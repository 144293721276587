import type { FC } from 'react';
import styled, { withTheme } from 'styled-components';

import { Badge } from '~/components/badge';
import { Colors, Theme } from '~/theme';
import { Routes } from '~/common/constants/routes';
import { useNavigation } from '~/common/hooks/useNavigation';
import { NavigationItem, NavigationRow } from '~/layout/styles';
import SvgArrowBack from '~/components/icons/ArrowBack';
import { colors } from '~/theme/colors';
import { Flex } from '~/components/wrappers/Flex';
import { Typography } from '~/components/typography/Typography';
import { useLocation } from '@remix-run/react';

interface HeaderProps {
  showLogo: boolean;
  cartItemCount: number;
  fontColor?: Colors;
  theme?: Theme;
  isMobileNavigationOpen?: boolean;
}

const Header: FC<HeaderProps> = withTheme(
  ({
    showLogo,
    cartItemCount,
    fontColor = colors.text,
    isMobileNavigationOpen,
  }) => {
    const { goToHome, goTo, goToShop } = useNavigation();
    const { pathname } = useLocation();
    const isProductPage = pathname.includes(Routes.Product);

    return (
      <header>
        <StyledHeader>
          <BackContainer
            alignItems="center"
            onClick={goToShop}
            cursor="pointer"
            isProductPage={isProductPage}
          >
            <SvgArrowBack />
            <Back variant="h1">back</Back>
          </BackContainer>
          <LogoWrapper onClick={goToHome}>
            {showLogo && (
              <Logo
                src="/share-image.png"
                alt="Logo of broken planet"
                loading="lazy"
              />
            )}
          </LogoWrapper>
          <Badge count={cartItemCount}>
            <StyledNavigationItem
              color={isMobileNavigationOpen ? undefined : fontColor}
              to={Routes.Cart}
              onClick={() => goTo(Routes.Cart)}
            >
              cart
            </StyledNavigationItem>
          </Badge>
        </StyledHeader>
      </header>
    );
  },
);

const LogoWrapper = styled.button`
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  height: 3.875rem;
  width: 5.875rem;

  @media ${({ theme }) => theme.breakpoints.sm} {
    position: relative;
    height: 2.25rem;
    width: 4.25rem;
  }
`;

const Logo = styled.img`
  cursor: pointer;
  width: 3.875rem;
  height: 3.875rem;

  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 2.25rem;
    height: 2.25rem;
  }
`;

const StyledHeader = styled(NavigationRow)`
  padding: 1.5rem 1.5rem 0;
  top: 0;
  height: auto;
  transition: box-shadow 0.15s ease-in-out;
  background-color: transparent;

  @media ${({ theme }) => theme.breakpoints.sm} {
    padding: 1rem 1rem 0;
  }
`;

const BackContainer = styled(Flex)<{ isProductPage?: boolean }>`
  display: none;

  @media ${({ theme }) => theme.breakpoints.sm} {
    display: ${({ isProductPage }) => (isProductPage ? 'flex' : 'none')};
  }
`;

const Back = styled(Typography)`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
  margin-top: 0;
  margin-left: 0.5rem;
  width: 100%;
`;

const StyledNavigationItem = styled(NavigationItem)`
  @media ${({ theme }) => theme.breakpoints.md} {
    font-size: 0.875rem;
  }
`;

export default Header;
