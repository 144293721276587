import { Routes } from './routes';

export const navigationItems = [
  { title: 'products', location: Routes.Shop },
  { title: 'sign up', location: Routes.SignUp },
  { title: 'size guide', location: Routes.Sizing },
  { title: 'sustainability', location: Routes.Sustainability },
  { title: 'terms', location: Routes.Terms },
  { title: 'shipping prices', location: Routes.ShippingPrices },
];

export default navigationItems;
